import { Pipe, PipeTransform } from '@angular/core';
import * as dfn from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { it, enGB, enUS, fr, frCH, itCH } from 'date-fns/locale';
import { SaleEventService } from 'src/app/sale-event-service.service';
import { AppTranslationService } from 'src/core-modules/localization/localization.service';
const formatMapping: { [key: string]: string } = {
  short: 'Pp',
  medium: 'PPpp',
  long: 'PPPPpppp',
  full: 'PPPPPPPPpppp',
  shortDate: 'P',
  mediumDate: 'PP',
  longDate: 'PPPP',
  fullDate: 'PPPPPPPP',
  shortTime: 'p',
  mediumTime: 'pp',
  longTime: 'ppp',
  fullTime: 'pppp',
  EEE: 'iii', // Abbreviated weekday name
  EEEE: 'iiii', // Full weekday name
  EEEEE: 'iiiii', // Narrow weekday name
};
const localeMapping: { [key: string]: Locale } = {
  'it-it': it,
  'en-gb': enGB,
  'en-us': enUS,
  'fr-fr': fr,
  'ch-fr': frCH,
  'ch-it': itCH,
};
const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;

@Pipe({
  name: 'saleEventDate',
})
export class SaleEventDatePipe implements PipeTransform {
  constructor(private tsvc: AppTranslationService) {}

  mapAngularFormatToFns(format: string): string {
    return formatMapping[format] || format;
  }
  mapInputLocaleToLocale(input: string): Locale {
    return localeMapping[input.toLowerCase()] || it; // Default to 'en-us' if no match
  }
  isIsoDateString(value: string): boolean {
    return isoDateRegex.test(value);
  }

  transform(
    value: string | null | undefined,
    format?: string,
    timezone?: string,
    locale?: string
  ): string | null {
    if (!value) {
      return null;
    }

    const dateValue = value.trim();
    // console.log(
    //   '🚀 ~ file: sale-event-date.pipe..ts:58 ~ SaleEventDatePipe ~ dateValue:',
    //   dateValue
    // );

    let inputDate = new Date(dateValue);
    let offset: string = '+00:00';
    if (this.isIsoDateString(dateValue)) {
      offset = '+00:00';
      // console.log("🚀 ~ file: sale-event-date.pipe..ts:70 ~ FORCED OFFSET:", offset)
    } else {
      //// Estrai l'offset dalla stringa della data
      offset = value.slice(-6); // Supponendo che l'offset sia alla fine della stringa
    }
    // console.log(
    //   '🚀 ~ file: sale-event-date.pipe..ts:70 ~ SaleEventDatePipe ~ offset:',
    //   offset
    // );
    //// Converte la data UTC in una data nel fuso orario specificato
    const zonedDate = utcToZonedTime(inputDate, offset); // Nota che stiamo usando l'offset estratto
    // console.log(
    //   '🚀 ~ file: sale-event-date.pipe..ts:64 ~ SaleEventDatePipe ~ zonedDate:',
    //   zonedDate
    // );

    //// Formatta la data
    const output = dfn.format(
      zonedDate,
      this.mapAngularFormatToFns(format ?? 'Pp'),
      {
        locale: this.mapInputLocaleToLocale(
          locale ?? this.tsvc.currentLanguage.value
        ),
      }
    );

    return output;
  }
}
